import '../style-modules/katapult-scrollbars.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { Debouncer } from '@polymer/polymer/lib/utils/debounce.js';
import { timeOut } from '@polymer/polymer/lib/utils/async.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import { DaysBetween, FormatDate, HowLong } from '../../modules/Date.js';
import '../katapult-elements/katapult-button.js';
class KatapultChat extends PolymerElement {
  static get template() {
    return html`
      <style include="katapult-scrollbars">
        :host {
          display: flex;
          flex-direction: column;
          width: 400px;
        }
        #messagesContainer {
          max-height: 600px;
          overflow-y: auto;
          padding-right: 16px;
        }
        #inputContainer {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
        }
        #inputContainer > paper-textarea {
          flex-grow: 1;
        }
        .messageBubble {
          width: fit-content;
          padding: 6px 12px;
          background-color: var(--paper-grey-200);
          border-radius: 4px;
          white-space: pre-line;
        }
        .messageBubble:first-of-type {
          border-top-right-radius: 16px;
          border-top-left-radius: 16px;
        }
        .messageBubble:last-of-type {
          border-bottom-right-radius: 16px;
          border-bottom-left-radius: 16px;
        }
        .messageGroup[me] .messageBubble {
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        }
        .messageGroup:not([me]) .messageBubble {
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }
        .messageBubble:not(:last-of-type) {
          margin-bottom: 2px;
        }
        .messageWrapper,
        .messageContainer {
          display: flex;
          flex-direction: column;
        }
        .messageGroup {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
        }
        .messageGroup:not(:last-of-type) {
          margin-bottom: 16px;
        }
        .messageGroup[me] {
          flex-direction: row-reverse;
        }
        .messageGroup[me] .messageContainer,
        .messageGroup[me] .messageWrapper {
          align-items: flex-end;
        }
        user-chip {
          padding: 0;
          margin-right: 6px;
        }
        .messageGroup[me] user-chip {
          margin-right: 0;
          margin-left: 6px;
        }
        .messageFooter {
          font-size: 9pt;
          color: var(--primary-text-color-faded);
        }
      </style>
      <firebase-query id="messages" start-at="[[startAt]]" data="{{messages}}" disabled="[[!startAt]]"> </firebase-query>
      <firebase-document path=".info/serverTimeOffset" data="{{serverTimeOffset}}"> </firebase-document>
      <div id="messagesContainer" on-scroll="scroll">
        <template is="dom-repeat" items="[[messageGroups]]" as="group" on-dom-change="messagesAdded">
          <div class="messageGroup" me$="[[isMe(group.sender)]]">
            <user-chip
              user-group="[[userGroup]]"
              uid="[[group.sender]]"
              email="{{group.email}}"
              hide-name=""
              on-dom-change="messagesAdded"
            ></user-chip>
            <div class="messageContainer">
              <div class="messageWrapper">
                <template is="dom-repeat" items="[[group.messages]]" as="message">
                  <div class="messageBubble">[[message.text]]</div>
                </template>
              </div>
              <div class="messageFooter">
                <template is="dom-if" if="[[!isMe(group.sender)]]">
                  <span>[[group.email]] -</span>
                </template>
                <template is="dom-if" if="[[group.timestamp]]">
                  <span>[[formatTime(group.timestamp, serverTimeOffset, updateTimestampsTrigger)]]</span>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div id="inputContainer">
        <paper-textarea on-keydown="inputKeydown" value="{{messageText}}" label="Message" no-label-float=""></paper-textarea>
        <katapult-button style="margin: 8px;" color="var(--secondary-color)" on-click="sendMessage">send</katapult-button>
      </div>
    `;
  }

  static get is() {
    return 'katapult-chat';
  }
  static get properties() {
    return {
      batchSize: {
        type: Number,
        value: 20
      },
      loadingMessages: {
        type: Boolean,
        value: false
      },
      messageGroups: {
        type: Array
      },
      messages: {
        type: Array
      },
      messageText: {
        type: String
      },
      path: {
        type: String
      },
      serverTimeOffset: {
        type: Number,
        value: 0
      },
      startAt: {
        type: String,
        value: null
      },
      userGroup: {
        type: String
      }
    };
  }
  static get observers() {
    return ['pathChanged(path)', 'updateMessageGroups(messages.*)', 'messageGroupsChanged(messageGroups.*)'];
  }
  ready() {
    super.ready();
    this.updateTimestampsTrigger = false;
    setInterval(() => (this.updateTimestampsTrigger = !this.updateTimestampsTrigger), 60000);
  }
  formatTime(timestamp, offset) {
    let adjustedTimestamp = timestamp - offset;
    if (DaysBetween(Date.now(), adjustedTimestamp)) return FormatDate(adjustedTimestamp);
    else {
      let temp = HowLong(Date.now() - adjustedTimestamp);
      if (temp[0].includes('seconds')) return 'Now';
      else return temp[0];
    }
  }
  isMe(uid) {
    return katapultAuth.user.uid === uid;
  }
  inputKeydown(e) {
    if (e.key === 'Enter') {
      if (e.ctrlKey || e.metaKey) {
        e.currentTarget.value += '\n';
      } else {
        e.stopPropagation();
        e.preventDefault();
        this.sendMessage();
      }
    }
  }
  loadMessages() {
    this.loadingMessages = true;
    return FirebaseWorker.ref(this.path)
      .limitToLast(this.batchSize + (this.get('messages.length') || 0))
      .once('value')
      .then((s) => {
        for (let messageKey in s.val()) {
          this.startAt = messageKey;
          break;
        }
      })
      .finally(() => {
        this.loadingMessages = false;
      });
  }
  messagesAdded() {
    this.messagesAddedDebounce = Debouncer.debounce(this.messagesAddedDebounce, timeOut.after(0), () => {
      if (!this.messagesFirstAdded) {
        this.$.messagesContainer.scrollTop = this.$.messagesContainer.scrollHeight - this.$.messagesContainer.offsetHeight;
        this.messagesFirstAdded = true;
      }
      if (this.distanceFromBottom < 10) {
        this.$.messagesContainer.scrollTop = this.$.messagesContainer.scrollHeight - this.$.messagesContainer.offsetHeight;
      } else if (this.containerScrollTop === 0) {
        this.$.messagesContainer.scrollTop =
          this.$.messagesContainer.scrollHeight - this.$.messagesContainer.offsetHeight - this.distanceFromBottom;
      }
    });
  }
  messageGroupsChanged() {
    // if (this.$.messagesContainer)
  }
  pathChanged(path) {
    // Check to make sure the path isn't broken because the job_id is blank
    if (path.indexOf('//') == -1) {
      if (this.$.messages.path !== this.path) {
        this.loadMessages().then(() => {
          this.$.messages.path = this.path;
        });
      }
    }
  }
  scroll() {
    if (this.$.messagesContainer.scrollTop === 0 && !this.loadingMessages) this.loadMessages();
    this.containerScrollTop = this.$.messagesContainer.scrollTop;
    this.distanceFromBottom =
      this.$.messagesContainer.scrollHeight - this.$.messagesContainer.offsetHeight - this.$.messagesContainer.scrollTop;
  }
  sendMessage() {
    if (this.messageText && this.messageText.length < 1000 && katapultAuth.user.uid && !this.path.includes('//')) {
      FirebaseWorker.ref(this.path)
        .push({ text: this.messageText, sender: katapultAuth.user.uid, timestamp: firebase.database.ServerValue.TIMESTAMP })
        .then(() => {
          this.messageText = '';
        });
    }
  }
  updateMessageGroups() {
    let groups = [];
    let cluster = [];
    let clusterTimestamp = 0;
    for (let i = 0; i < this.messages.length; i++) {
      let message = this.messages[i];
      if (!cluster.length || cluster[0].sender === message.sender) {
        cluster.push(message);
        if (message.timestamp > clusterTimestamp) clusterTimestamp = message.timestamp;
      } else {
        groups.push({ sender: cluster[0].sender, messages: cluster, timestamp: clusterTimestamp });
        cluster = [message];
        clusterTimestamp = message.timestamp;
      }
    }
    if (cluster.length) groups.push({ sender: cluster[0].sender, messages: cluster, timestamp: clusterTimestamp });
    this.messageGroups = groups;
  }
}
window.customElements.define(KatapultChat.is, KatapultChat);
